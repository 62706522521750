interface ConnectionEdge {
  node?: {
    id: string
  }
}

interface NodeWithConnectionEdge {
  edges?: ConnectionEdge[]
}

export const mapEdge = <T extends ConnectionEdge>(edge: T): T['node'] => edge.node
export function collectNodes<T extends NodeWithConnectionEdge>(
  connection: T,
): T['edges'][0]['node'][] {
  if (connection == null) return []
  const { edges } = connection
  if (edges == null) return []
  return edges?.map((edge) => edge.node) ?? []
}
